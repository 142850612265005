import React, { useState } from "react";
import { useDrop } from "react-dnd";
import TaskCard from "./TaskCard";
import { ItemTypes } from "./constants";
import "./TaskFlow.css";
import { Input } from "reactstrap";
import { patchFlow } from "../../helpers/backend"; // Importa la función para actualizar

const TaskColumn = ({
  title,
  tasks,
  moveTask,
  deleteTask,
  columnIndex,
  deleteColumn,
  openStepPanel,
  addTask,
  flowId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [columnTitle, setColumnTitle] = useState(title);
  const [isAdding, setIsAdding] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [assignee, setAssignee] = useState("");

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    hover: (draggedItem) => {
      if (draggedItem.columnIndex !== columnIndex) {
        moveTask(
          draggedItem.index,
          draggedItem.columnIndex,
          columnIndex,
          draggedItem.index
        );
        draggedItem.columnIndex = columnIndex;
      }
    },
  });
  const handleAddTask = () => {
    if (taskTitle) {
      addTask(taskTitle, dueDate, assignee);
      setTaskTitle("");
      setDueDate("");
      setAssignee("");
      setIsAdding(false);
    }
  };
  const handleEditTitle = async () => {
    setIsEditing(!isEditing);
    if (!isEditing) return; // Solo enviar al backend si se está finalizando la edición

    try {
      await patchFlow(flowId, { title: columnTitle }); // Actualiza el título en el backend
      console.log("Título del flujo actualizado con éxito");
    } catch (error) {
      console.error("Error actualizando el título del flujo:", error);
    }
  };

  const handleTitleChange = (e) => {
    setColumnTitle(e.target.value);
  };

  return (
    <div ref={drop} className="task-column">
      {isEditing ? (
        <Input
          value={columnTitle}
          onChange={handleTitleChange}
          onBlur={handleEditTitle}
          autoFocus
        />
      ) : (
        <h5 className="text-dark" onDoubleClick={handleEditTitle}>
          {columnTitle}
        </h5>
      )}
      <span className="delete-btn" onClick={deleteColumn}>
        ×
      </span>
      {!isAdding && (
        <div className="add-task-box" onClick={() => setIsAdding(true)}>
          <i className="fas fa-plus"></i> {/* Ícono de agregar */}
          <p>Agregar Tarea</p>
        </div>
      )}

      {tasks.map((task, index) => (
        <TaskCard
          key={task.id}
          task={task}
          index={index}
          columnIndex={columnIndex}
          deleteTask={deleteTask}
          openStepPanel={openStepPanel}
          moveTask={moveTask}
        />
      ))}
      {/* Formulario de agregar tarea */}
      {isAdding && (
        <div className="task-form">
          <input
            type="text"
            value={taskTitle}
            onChange={(e) => setTaskTitle(e.target.value)}
            placeholder="Título de la tarea"
            style={{ marginBottom: "10px" }}
          />
          <input
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="text"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            placeholder="Asignar a..."
            style={{ marginBottom: "10px" }}
          />
          <button onClick={handleAddTask}>Guardar Tarea</button>
          <button onClick={() => setIsAdding(false)}>Cancelar</button>
        </div>
      )}
    </div>
  );
};

export default TaskColumn;
