import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import SignaturePad from "react-signature-canvas";
import "./sigCanvas.css";

const Modalfirma = (props) => {
  const handleButtonClick = () => {
    props.onCloseClick(); // Llama a la función onCloseClick pasada como prop desde el componente padre
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.FirmaModal}
        toggle={props.onCloseClick}
        centered={true}
      >
        <ModalHeader toggle={handleButtonClick} tag="h4">
          FIRMA
        </ModalHeader>

        <ModalBody>
          <SignaturePad
            ref={props.sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          <button className="button" onClick={props.onCloseClick}>
            Cerrar
          </button>
          <button className="button" onClick={props.limpiar}>
            limpiar
          </button>

          <button className="button" onClick={props.guardar}>
            guardar
          </button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Modalfirma;
