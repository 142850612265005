import {
  PROJECT_LIST,
  PROJECT_FILTER,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DELETE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  PROJECT_CREATE_SUCCESS,
  PROJECT_LIST_SUCCESS,
  PROJECT_REQUEST_FAILED,
} from "../project/actionTypes";

export const projectList = () => {
  return {
    type: PROJECT_LIST,
    payload: {},
  };
};

export const projectCreate = (project) => {
  return {
    type: PROJECT_CREATE,
    payload: project,
  };
};

export const projectFilter = (changeUrl) => {
  return {
    type: PROJECT_FILTER,
    payload: { changeUrl },
  };
};

export const projectUpdate = (id, data) => {
  return {
    type: PROJECT_UPDATE,
    payload: { id, data },
  };
};

export const projectDelete = (id) => {
  return {
    type: PROJECT_DELETE,
    payload: { id },
  };
};


export const projectListSuccess = (project) => {
  return {
    type: PROJECT_LIST_SUCCESS,
    payload: project,
  };
};

export const projectCreateSuccess = (projectCreated) => {
  return {
    type: PROJECT_CREATE_SUCCESS,
    payload: projectCreated,
  };
};

export const projectUpdateSuccess = (project) => {
  return {
    type: PROJECT_UPDATE_SUCCESS,
    payload: project,
  };
};

export const projectDeleteSuccess = (projectDeleted) => {
  return {
    type: PROJECT_DELETE_SUCCESS,
    payload: projectDeleted,
  };
};


export const projectRequestFailed = (error) => {
  return {
    type: PROJECT_REQUEST_FAILED,
    payload: error,
  };
};

