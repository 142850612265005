// PROJECT requests
export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_CREATE = "PROJECT_CREATE";
export const PROJECT_FILTER = "PROJECT_FILTER";
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_READ = "PROJECT_READ";
export const PROJECT_PERMISSION = "PROJECT_PERMISSION";
export const PROJECT_CHANGE_PASSWORD = "PROJECT_CHANGE_PASSWORD";

// PROJECT success
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_READ_SUCCESS = "PROJECT_READ_SUCCESS";
export const PROJECT_CHANGE_PASSWORD_SUCCESS = "PROJECT_CHANGE_PASSWORD_SUCCESS";
export const PROJECT_PERMISSION_SUCCESS = "PROJECT_PERMISSION_SUCCESS";

// PROJECT failed
export const PROJECT_REQUEST_FAILED = "PROJECT_REQUEST_FAILED";
