import {
  PRODUCTO_LIST,
  PRODUCTO_FILTER,
  PRODUCTO_CREATE,
  PRODUCTO_CREATE_SUCCESS,
  PRODUCTO_LIST_SUCCESS,
  PRODUCTO_REQUEST_FAILED,
  PRODUCTO_UPDATE,
  PRODUCTO_UPDATE_SUCCESS,
  PRODUCTO_DELETE,
  PRODUCTO_DELETE_SUCCESS,

} from "../productos/actionTypes";

const initialState = {
  producto: [],
  productoCreated: {},
  productoUpdated: {},
  productoDeleted:false,
  loading: false,
  error: "",
};

const producto = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PRODUCTO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PRODUCTO_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PRODUCTO_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PRODUCTO_DELETE:
        state = {
          ...state,
          loading: true,
          productoDeleted: false,
        };
        break;
    case PRODUCTO_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        productoCreated: action.payload,
        error: "",
      };
      break;
    case PRODUCTO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        producto: action.payload,
        productoCreated: {},
        productoUpdated: {},
        productoDeleted: false,
        error: "",
      };
      break;
    case PRODUCTO_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        productoUpdated: action.payload,
        error: "",
      };
      break;
    case PRODUCTO_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        productoDeleted: true,
        error: "",
      };
      break;


    case PRODUCTO_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default producto;
