import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import logo3 from "../../assets/images/logo-symbol-tc.png";

const SidebarContent = (props) => {
  const ref = useRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const groups = String(user.groups);

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu");
    };

    initMenu();
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span key="t-dashboard">Dashboard</span>
              </Link>
            </li>
            {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
              <>
                {" "}
                <li>
                  <Link to="#">
                    <i className="fas fa-user"></i>
                    <span key="t-users">Usuarios</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/users">Usuarios</Link>
                    </li>
                    <li>
                      <Link to="/rol/add_rol">Roles</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/campaign">
                    <i className="fas fa-flag"></i>
                    <span key="t-Parametrizaciones">Campañas</span>
                  </Link>
                </li>
              </>
            ) : null}

            <li>
              <Link to="/contacts">
                <i className="fas fa-user-tie"></i>
                <span key="t-contacts">Contactos</span>
              </Link>
            </li>
            <li>
              <Link to="/leads">
                <i className="fas fa-users"></i>
                <span key="t-Parametrizaciones">Leads</span>
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <img src={logo3} alt="" height="25" className="ms-1 me-1" />
                <span key="t-canales">TrueContact</span>
              </Link>
            </li>
            <li>
              <Link to="/casos">
                <i className="fas fa-ticket-alt"></i>
                <span key="t-casos">Casos</span>
              </Link>
            </li>
            <li>
              <Link to={`/project`}>
                <i className="fas fa-tasks  "></i>
                <span key="t-survey"> Tareas </span>
              </Link>
            </li>
            <li>
              <Link to={`/survey`}>
                <i className="fas fa-question-circle fa-lg "></i>
                <span key="t-survey"> Encuestas </span>
              </Link>
            </li>
            <li>
              <Link to={`/contact/calendar`}>
                <i className="  fas fa-calendar-alt fa-lg ms-2"></i>
                <span key="t-calendar"> Mi Agenda </span>
              </Link>
            </li>
            <li>
              <Link to="/audits">
                <i className="  fas fa-store fa-md "></i>
                <span key="t-audits"> Tienda</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/productos">Productos</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/audits">
                <i className="  fas fa-clipboard-check fa-lg ms-2"></i>
                <span key="t-audits"> Auditoría</span>
              </Link>
            </li>
            {groups.includes("SuperAdmin") || groups.includes("Supervisor") ? (
              <li>
                <Link to="#">
                  <i className="fas fa-file-alt"></i>
                  <span key="t-file">Reportes</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/managementList">Lista de Gestiones</Link>
                  </li>
                  <li>
                    <Link to="/report_supervision">Reporte de Supervisión</Link>
                  </li>
                  <li>
                    <Link to="/report_for_hour">Reporte por Hora</Link>
                  </li>
                  <li>
                    <Link to="leads/report">Leads por Hora</Link>
                  </li>
                  {/*  <li><Link to="/report_for_time">Promedio de Tiempo</Link></li>*/}
                  <li>
                    <Link to="/report_for_bot">Ingresados al bot</Link>
                  </li>
                  <li>
                    <Link to="/issabelReport"> Reportes Issabel</Link>
                  </li>
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));
