import React, { useState, useRef, useEffect } from "react";
import { Row, Input, Button, Modal, Col } from "reactstrap";

import "reactjs-popup/dist/index.css";

import "./sigCanvas.css";
import { patchCasosUpdateInfomre } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";
import Modalfirma from "./Modalfirma";
import {  getCasosRead,  } from "../../helpers/backend";

function ModalInforme(props) {
    const [descripcion, setDescripcion] = useState("");
    const [observaciones, setObservaciones] = useState("");
    const URL = process.env.REACT_APP_API_URL.split("/api").join("");
    const [personaRecibe, setPersonaRecibe] = useState("");
    const [cargo, setCargo] = useState("");
    const [showAlertSuccess, setShowAlertSuccess] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [actionAlert, setActionAlert] = useState(false);
    const [FirmaModal, setFirmaModal] = useState(false);
    const [firma, setFirma] = useState("");
    const sigCanvas = useRef({});
  
     const getCasosData = async () => {
        const response = await getCasosRead(props.casosId);
        const data = response.data.informe[0];
        console.log(data);
        setDescripcion(data.descripcion);
        setObservaciones(data.observaciones);
        setPersonaRecibe(data.persona_recibe);
        setCargo(data.cargo);
        setFirma(data.firma);
      };

       useEffect(() => {
          getCasosData();
         
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
    const popupRef = useRef();
    function handleClose() {
        popupRef.current.close();
      }


      const limpiar = () => sigCanvas.current.clear();

      const [imageUrl, setImageUrl] = useState(null);
      const [imageUrl1, setImageUrl1] = useState(null);
    


      const guardar = () => {
        const imageUrl = sigCanvas.current.toDataURL("image/png");
        const cleanImageUrl = imageUrl.substring("data:image/png;base64,".length);
        setImageUrl(cleanImageUrl);
        setImageUrl1(imageUrl);
        setFirmaModal(false);
      };
    
 
    console.log(props);
    const handleCloseFirmaModal = () => {
        setFirmaModal(false);
      };
    
      
  const handleFirmaModal = () => {
    setFirmaModal(true);
  };
  const actionSuccess = async () => {
    // Preparar los datos a enviar
    const dataSend = {
      descripcion: descripcion,
      persona_recibe: personaRecibe,
      cargo: cargo,
      observaciones: observaciones,
      // No se agrega firma si imageUrl es null
    };
    
    if (imageUrl !== null) {
      dataSend.firma = imageUrl;
    }
  
    try {
      setActionAlert(true); // Mostrar que la acción está en progreso
  
      console.log("Caso ID:", props.casosId);
  
      // Realizar la solicitud con patchCasosUpdateInfomre
      const response = await patchCasosUpdateInfomre(props.casosId, dataSend);
  
      if (response.status === 200) {
        setShowAlertSuccess(true); // Mostrar éxito
      } else {
        setErrorAlert(true); // Mostrar error
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setErrorAlert(true); // Mostrar error en caso de excepción
    } finally {
      setActionAlert(false); // Ocultar el indicador de acción
    }
  };
  
    
  return (
    <div>
        <>
        {FirmaModal ? (
        <Modalfirma
          FirmaModal={FirmaModal}
          actionAlert={actionAlert}
          onCloseClick={handleCloseFirmaModal}
          handleClose={handleClose}
          limpiar={limpiar}
          guardar={guardar}
          sigCanvas={sigCanvas}
        ></Modalfirma>
      ) : null}

       {showAlertSuccess ? (
              <SweetAlert
                title="Hecho!"
                success
                showConfirm={false}
                timeout={2000}
                onConfirm={() => {
                  props.getCasosData();
                  setShowAlertSuccess(false);
                  props.handleCloseModal();
                }}
              ></SweetAlert>
            ) : null}
            {errorAlert ? (
              <SweetAlert
                title="Error!"
                danger
                showConfirm={false}
                timeout={2000}
                onConfirm={() => {
                  props.getCasosData();
                  setErrorAlert(false);
                }}
              ></SweetAlert>
            ) : null}
            <Modal
              isOpen={props.modalInforme}
              toggle={() => props.handleCloseModal()}
            >
              <div className="modal-header">
                <h3 className="modal-title mt-2">Editar Informe</h3>
                <button
                  type="button"
                  onClick={() => {
                    props.handleCloseModal();
                  }}
                  className="btn"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row className="mb-3 p-2">
                  <Input
                    type="text"
                    onChange={(e) => setDescripcion(e.target.value)}
                    placeholder="Descripcion"
                    value={descripcion}
                  />
                </Row>
      
                <Row className="mb-3 p-2">
                    <Col>
                  <Input
                    type="text"
                    onChange={(e) => setPersonaRecibe(e.target.value)}
                    placeholder="Persona recibe"
                    value={personaRecibe}
                  />
                      </Col>
                        <Col>
                  <Input
                    type="text"
                    onChange={(e) => setCargo(e.target.value)}
                    placeholder="Cargo"
                    value={cargo}
                  />
                </Col>
                </Row>
                <Row className="mb-3 p-2">
                  <Input
                    type="text"
                    onChange={(e) => setObservaciones(e.target.value)}
                    placeholder="Observaciones"
                    value={observaciones}
                  />
                </Row>

                <Row>

                <button onClick={handleFirmaModal} className="button-firma">
              Firma
            </button>

 </Row>
 
 {imageUrl1 ? (
              <Row className="row-firma">
                <img
                  src={imageUrl1}
                  alt="mi firma"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    border: "1px solid blick",
                    width: "150px",
                  }}
                />
              </Row>
            ) : null}

            {!imageUrl1 ? (
                
            <Row className="mt-2">
              { firma? (
                firma !== "" ? (
                  <Row className="justify-content-around">
                    <img
                      src={`${URL}${firma}`}
                      alt="mi firma"
                      style={{
                        display: "block",
                        margin: "10px  auto",
                        border: "2px dashed #000000",
                        width: "350px",
                        backgroundColor: "white",
                      }}
                    />
                  </Row>
                ) : null
              ) : null}
            </Row>
            ): null}
              
              </div>
              <div className="modal-footer">
                <Button color="secondary" onClick={() => props.handleCloseModal()}>
                  Close
                </Button>
                <Button color="primary" onClick={() => actionSuccess()}>
                  Save changes
                </Button>
              </div>
            </Modal>
          </>
    </div>
  )
}

export default ModalInforme
