import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  Form,
  InputGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import styles from "../Contacts/Contacts.module.scss";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import { projectFilter, projectList } from "../../store/project/actions";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getContactsFullList,
  getGrupoContactoFilter,
  postProjectCreate,
} from "../../helpers/backend";

const ProjectList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState([]);
  const [contactGroup, setContactGroup] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    descriptions: "",
    group_contact: null,
    contact: null,
  });
  // Obtener lista completa de contactos
  const getContactData = async () => {
    const response = await getContactsFullList();
    setContacts(response.data);
  };
  const getContactGroup = async () => {
    try {
      const response = await getGrupoContactoFilter("FullList/");
      setContactGroup(response.data);
    } catch (error) {
      console.error("Error fetching contact group data", error);
    }
  };
  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    dispatch(projectFilter(`?search=${search}`));
  };
  const resetSearch = () => {
    dispatch(projectFilter(`?search=`));
    setSearch("");
  };
  const toggleModal = () => setModal(!modal);

  const onSubmit = async () => {
    const response = await postProjectCreate(formData);
    dispatch(projectList());
    console.log(response);
    setModal(false);
  };
  useEffect(() => {
    dispatch(projectList());
    getContactData();
    getContactGroup();
  }, [dispatch]);

  const params = {
    count: props.project.count,
    next: props.project.next,
    previous: props.project.previous,
    results: props.project.results,
  };
  console.log(params);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Proyecto</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="TrueContact" breadcrumbItem="Proyecto" />
          <Card>
            <CardBody>
              <Row>
                <Col md={7}>
                  <Link
                    className="btn btn-primary waves-effect waves-light"
                    to="#"
                    onClick={() => setModal(true)}
                  >
                    Agregar Proyecto{" "}
                  </Link>
                </Col>
                <Col md={5}>
                  <Form className="search-box" onSubmit={onSubmitSearch}>
                    <InputGroup>
                      <Input
                        type="text"
                        className="form-control"
                        name="search"
                        placeholder="Buscar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <Button color="primary" onClick={resetSearch}>
                        <span className=" bx bx-x fa-lg align-middle"></span>
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {props.loading ? (
            <Loader />
          ) : (
            <div className={`text-center ${styles["card-container"]}`}>
              {params.results && params.results.length > 0 ? (
                params.results.map((item) => (
                  <Card
                    key={item.id}
                    className={`${styles.card} custom-card`}
                    onClick={() => history.push(`/tasks/${item.id}`)}
                  >
                    <CardBody className="d-flex flex-column justify-content-between h-100">
                      <div className=" justify-content-center">
                        <h5 className="text-dark">{item.name}</h5>
                        <h6>{item.descriptions}</h6>
                      </div>
                    </CardBody>
                  </Card>
                ))
              ) : (
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
              )}
            </div>
          )}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={30}
            baseUrl="task/project/"
            filterFunction={projectList}
          />
        </Container>
      </div>
      {modal && (
        <Modal isOpen={modal} toggle={() => toggleModal(null)} size="lg">
          <ModalBody>
            <h4 className="mb-4">Agregar Proyecto</h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre del Proyecto
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="descriptions" className="form-label">
                  Descripción
                </label>
                <textarea
                  className="form-control"
                  id="descriptions"
                  value={formData.descriptions}
                  onChange={(e) => handleChange("descriptions", e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="group_contact" className="form-label">
                  Grupo de Contactos
                </label>
                <Select
                  id="group_contact"
                  options={contactGroup}
                  value={formData.group_contact}
                  onChange={(selected) =>
                    handleChange("group_contact", selected)
                  }
                  placeholder="Seleccione un grupo de contactos"
                  isClearable
                />
              </div>
              <div className="mb-3">
                <label htmlFor="contact" className="form-label">
                  Contacto
                </label>
                <Select
                  id="contact"
                  options={contacts.map(item => ({
                    label: item.get_full_name,
                    value: item.id
                  }))}
                  value={formData.contact}
                  onChange={(selected) => handleChange("contact", selected.value)}
                  placeholder="Seleccione un contacto"
                  isClearable
                />
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  className="me-2 text-dark"
                  onClick={() => toggleModal(null)}
                >
                  Cerrar
                </Button>
                <Button type="submit" color="primary">
                  Guardar
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { project, loading } = state.Project;
  return { project, loading };
};

export default withRouter(
  connect(mapStateToProps, { projectList })(ProjectList)
);
