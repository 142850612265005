import {
  PROJECT_LIST,
  PROJECT_FILTER,
  PROJECT_CREATE,
  PROJECT_CREATE_SUCCESS,
  PROJECT_LIST_SUCCESS,
  PROJECT_REQUEST_FAILED,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
  PROJECT_DELETE,
  PROJECT_DELETE_SUCCESS,

} from "../project/actionTypes";

const initialState = {
  project: [],
  projectCreated: {},
  projectUpdated: {},
  projectDeleted:false,
  loading: false,
  error: "",
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_DELETE:
        state = {
          ...state,
          loading: true,
          projectDeleted: false,
        };
        break;
    case PROJECT_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectCreated: action.payload,
        error: "",
      };
      break;
    case PROJECT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        project: action.payload,
        projectCreated: {},
        projectUpdated: {},
        projectDeleted: false,
        error: "",
      };
      break;
    case PROJECT_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectUpdated: action.payload,
        error: "",
      };
      break;
    case PROJECT_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        projectDeleted: true,
        error: "",
      };
      break;


    case PROJECT_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default project;
