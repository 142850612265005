import {
  PRODUCTO_LIST,
  PRODUCTO_FILTER,
  PRODUCTO_CREATE,
  PRODUCTO_UPDATE,
  PRODUCTO_DELETE,
  PRODUCTO_UPDATE_SUCCESS,
  PRODUCTO_DELETE_SUCCESS,
  PRODUCTO_CREATE_SUCCESS,
  PRODUCTO_LIST_SUCCESS,
  PRODUCTO_REQUEST_FAILED,
} from "../productos/actionTypes";

export const productoList = () => {
  return {
    type: PRODUCTO_LIST,
    payload: {},
  };
};

export const productoCreate = (producto) => {
  return {
    type: PRODUCTO_CREATE,
    payload: producto,
  };
};

export const productoFilter = (changeUrl) => {
  return {
    type: PRODUCTO_FILTER,
    payload: { changeUrl },
  };
};

export const productoUpdate = (id, data) => {
  return {
    type: PRODUCTO_UPDATE,
    payload: { id, data },
  };
};

export const productoDelete = (id) => {
  return {
    type: PRODUCTO_DELETE,
    payload: { id },
  };
};


export const productoListSuccess = (producto) => {
  return {
    type: PRODUCTO_LIST_SUCCESS,
    payload: producto,
  };
};

export const productoCreateSuccess = (productoCreated) => {
  return {
    type: PRODUCTO_CREATE_SUCCESS,
    payload: productoCreated,
  };
};

export const productoUpdateSuccess = (producto) => {
  return {
    type: PRODUCTO_UPDATE_SUCCESS,
    payload: producto,
  };
};

export const productoDeleteSuccess = (productoDeleted) => {
  return {
    type: PRODUCTO_DELETE_SUCCESS,
    payload: productoDeleted,
  };
};


export const productoRequestFailed = (error) => {
  return {
    type: PRODUCTO_REQUEST_FAILED,
    payload: error,
  };
};

