import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import "./TaskFlow.css";

const StepPanel = ({ task, isOpen, toggle }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleStepClick = (step) => {
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
  };
console.log(task)
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-modal-step" size="xl">
      <ModalHeader toggle={toggle}>{task.title}</ModalHeader>
      <ModalBody>
        {/* Task Info */}
        <div className="task-info">
          <h5 className="task-title">{task.title}</h5>
          <p className="task-meta">Completada hace 3 minutos por {task.created_by}</p>
        </div>

        {/* Tags Section */}
        <div className="tags-container">
          {task.tags ? task.tags.map((tag, index) => (
            <span key={index} className="tag-item">{tag}</span>
          )) : "Sin etiquetas"}
        </div>

        {/* Dropdowns Section */}
        <div className="form-section">
          <FormGroup>
            <Label for="deposito">Depósito</Label>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret className="w-100">
                Siguiente
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>Primero</DropdownItem>
                <DropdownItem>Siguiente</DropdownItem>
                <DropdownItem>Último</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </FormGroup>

          <FormGroup>
            <Label for="progreso">Progreso</Label>
            <Input type="select" id="progreso">
              <option>Completada</option>
              <option>En progreso</option>
              <option>Pendiente</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="prioridad">Prioridad</Label>
            <Input type="select" id="prioridad">
              <option>Alta</option>
              <option>Media</option>
              <option>Baja</option>
            </Input>
          </FormGroup>
        </div>

        {/* Dates Section */}
        <div className="date-section">
          <FormGroup>
            <Label for="fecha-inicio">Fecha de inicio</Label>
            <Input type="date" id="fecha-inicio" placeholder="Iniciar en cualquier momento" />
          </FormGroup>

          <FormGroup>
            <Label for="fecha-vencimiento">Fecha de vencimiento</Label>
            <Input type="date" id="fecha-vencimiento" defaultValue="2024-11-27" />
          </FormGroup>
        </div>

        <FormGroup check>
          <Label check>
            <Input type="checkbox" /> Mostrar en la tarjeta
          </Label>
        </FormGroup>

        {/* Notes Section */}
        <FormGroup>
          <Label for="notas">Notas</Label>
          <Input type="textarea" id="notas" placeholder="Escriba una descripción o agregue notas aquí" />
        </FormGroup>

        {/* Steps Section */}
        <h6>Lista de pasos</h6>
        <ListGroup>
          {task.steps.length > 0 ? (
            task.steps.map((step, index) => (
              <ListGroupItem
                key={index}
                className={completedSteps.includes(step) ? "completed-step" : ""}
                onClick={() => handleStepClick(step)}
              >
                {step}
              </ListGroupItem>
            ))
          ) : (
            <p>No hay pasos disponibles</p>
          )}
        </ListGroup>

        {/* Add Attachments Button */}
        <Button color="primary" className="mt-3">
          Agregar datos adjuntos
        </Button>

        {/* Assign Contacts Section */}
        <div className="assigned-list">
          <h6>Asignar a:</h6>
          <Button color="link" className="icon-button">
            <i className="fas fa-user-plus"></i>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StepPanel;
