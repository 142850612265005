import { call, put, takeEvery } from "redux-saga/effects";

import {
  PRODUCTO_LIST,
  PRODUCTO_FILTER,
  PRODUCTO_CREATE,
  PRODUCTO_UPDATE,
  PRODUCTO_DELETE,
} from "./actionTypes";

import {
 productoListSuccess,
 productoCreateSuccess,
 productoUpdateSuccess,
 productoDeleteSuccess,
 productoRequestFailed,
} from "./actions";

import {
  getProductoList,
  getProductoFilter,
  postProductoCreate,
  patchProductoUpdate,
  deleteProducto,

} from "../../helpers/backend";

function*productoListSaga() {
  console.log("Trayendo lista de producto...");
  try {
    const response = yield call(getProductoList);
    if (response.status === 200) {
      yield put(productoListSuccess(response.data));
    } else {
      yield put(productoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(productoRequestFailed(error.response.data));
  }
}

function*productoCreateSaga({ payload:producto }) {
  console.log("Creando nuevo producto...");
  try {
    const response = yield call(postProductoCreate,producto);
    if (response.status === 201) {
      yield put(productoCreateSuccess(response.data));
    } else {
      yield put(productoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(productoRequestFailed(error.response.data));
  }
}

function*productoFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getProductoFilter, { changeUrl });
    if (response.status === 200) {
      yield put(productoListSuccess(response.data));
    } else {
      yield put(productoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(productoRequestFailed(error.response.data));
  }
}

function*productoUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del PRODUCTO...");
  try {
    const response = yield call(patchProductoUpdate, { id }, data);
    if (response.status === 200) {
      yield put(productoUpdateSuccess(response.data));
    } else {
      yield put(productoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(productoRequestFailed(error.response.data));
  }
}

function*productoDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del PRODUCTO...");
  try {
    const response = yield call(deleteProducto, id );
    if (response.status === 204) {
      yield put(productoDeleteSuccess(response.data));
    } else {
      yield put(productoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(productoRequestFailed(error.response.data));
  }
}

export default function*productoSaga() {
  yield takeEvery(PRODUCTO_LIST,productoListSaga);
  yield takeEvery(PRODUCTO_FILTER,productoFilterSaga);
  yield takeEvery(PRODUCTO_CREATE,productoCreateSaga);
  yield takeEvery(PRODUCTO_UPDATE,productoUpdateSaga);
  yield takeEvery(PRODUCTO_DELETE,productoDeleteSaga);
}
