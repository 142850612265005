import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskColumn from "./TaskColumn";
import StepPanel from "./StepPanel";
import SweetAlert from "react-bootstrap-sweetalert";
import "./TaskFlow.css";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import {
  delFlow,
  delTask,
  getTask,
  patchTask,
  postFlow,
  postTask,
} from "../../helpers/backend";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const TaskFlow = () => {
  const id = useParams().id
  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startX, scrollLeft;
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskFlows, setTaskFlows] = useState([]);
  const [alert, setAlert] = useState(null);
  const [stepOpen, setStepOpen] = useState(false)

  const getTaskList = async () => {
    const response = await getTask(id);
    const sortedFlows = response.data.flow.sort((a, b) => a.order - b.order);
    sortedFlows.forEach((flow) => {
      if (flow.tasks) {
        flow.tasks.sort((a, b) => a.order - b.order);
      }
    });
    setTaskFlows(sortedFlows);
  };

  useEffect(() => {
    getTaskList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleMouseDown = (e) => {
    isDragging = true;
    scrollContainerRef.current.classList.add("dragging");
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Ajusta la sensibilidad del desplazamiento
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    isDragging = false;
    scrollContainerRef.current.classList.remove("dragging");
  };
  const moveTask = (taskIndex, fromColumnIndex, toColumnIndex, hoverIndex) => {
    const newTaskFlows = [...taskFlows];
    if (
      newTaskFlows[fromColumnIndex]?.tasks &&
      newTaskFlows[toColumnIndex]?.tasks &&
      taskIndex >= 0 &&
      taskIndex < newTaskFlows[fromColumnIndex].tasks.length
    ) {
      // Elimina la tarea desde la columna de origen
      const [movedTask] = newTaskFlows[fromColumnIndex].tasks.splice(
        taskIndex,
        1
      );

      // Inserta la tarea en la nueva posición de la columna de destino
      newTaskFlows[toColumnIndex].tasks.splice(hoverIndex, 0, movedTask);

      // Actualiza el atributo `order` para ambas columnas
      newTaskFlows[fromColumnIndex].tasks.forEach((task, index) => {
        if (task) task.order = index + 1;
      });

      newTaskFlows[toColumnIndex].tasks.forEach((task, index) => {
        if (task) task.order = index + 1;
      });

      // Si se mueve a una nueva columna, actualiza el atributo `flow`
      if (fromColumnIndex !== toColumnIndex) {
        movedTask.flow = newTaskFlows[toColumnIndex].id;
      } else {
        delete movedTask.flow;
      }

      // Actualiza el estado de las tareas
      setTaskFlows(newTaskFlows);

      // Enviar actualizaciones a la API
      updateTaskOrder(newTaskFlows.flatMap((flow) => flow.tasks));
    } else {
      console.error("Índices inválidos en el movimiento de tareas");
    }
  };

  const updateTaskOrder = async (updatedTasks) => {
    try {
      for (let task of updatedTasks) {
        const updateData = { order: task.order };
        if (task.hasOwnProperty("flow") && typeof task.flow === "number") {
          updateData.flow = task.flow;
        }
        const response = await patchTask(task.id, updateData);
        console.log(response)
      }
    } catch (error) {
      console.error("Error actualizando el orden de las tareas:", error);
    }
  };

  const addTask = async (task) => {
    try {
      await postTask({
        flow: task.flowId,
        title: task.title,
      });
      getTaskList();
    } catch (error) {
      console.error("Error al agregar la tarea:", error);
    }
  };

  const hideAlert = () => setAlert(null);
  
  const deleteTask = async (taskId) => {
    try {
        await delTask(taskId); // Elimina la tarea en el backend
        const newTaskFlows = taskFlows.map((flow) => ({
            ...flow,
            tasks: flow.tasks.filter((task) => task.id !== taskId),
        }));
        setTaskFlows(newTaskFlows); // Actualiza el estado
        setAlert(
            <SweetAlert
                success
                title="¡Eliminada!"
                onConfirm={hideAlert}
                timeout={2000}
                showConfirm={false}
            >
                La tarea ha sido eliminada con éxito.
            </SweetAlert>
        );
    } catch (error) {
        console.error("Error eliminando la tarea:", error);
        setAlert(
            <SweetAlert
                danger
                title="Error"
                onConfirm={hideAlert}
                timeout={2000}
                showConfirm={false}
            >
                No se pudo eliminar la tarea. Intenta nuevamente.
            </SweetAlert>
        );
    }
};

const confirmDeleteTask = (taskId) => {
  setAlert(
      <SweetAlert
          warning
          showCancel
          confirmBtnText="Eliminar"
          confirmBtnBsStyle="danger"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="dark"
          title="¿Estás seguro?"
          onConfirm={() => deleteTask(taskId)}
          onCancel={hideAlert}
          focusCancelBtn
      >
          Esta acción no se puede deshacer.
      </SweetAlert>
  );
};

  const addColumn = async (columnTitle) => {
    try {
      const response = await postFlow({
        project: id, 
        title: columnTitle,
      });

      const newFlow = { ...response.data, tasks: [] }; // Aseguramos que el flujo tenga una lista de tareas vacía
      setTaskFlows([...taskFlows, newFlow]); // Actualizamos el estado

      console.log("Flujo agregado con éxito:", newFlow);
    } catch (error) {
      console.error("Error al agregar el flujo:", error);
    }
  };

  const deleteFlow = async (columnIndex, flowId) => {
    try {
      await delFlow(flowId); // Realiza la petición para eliminar el flow en el backend
      const newTaskFlows = [...taskFlows];
      newTaskFlows.splice(columnIndex, 1); // Elimina la columna del estado
      setTaskFlows(newTaskFlows);
      setAlert(
        <SweetAlert
          success
          title="¡Eliminado!"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          El flujo ha sido eliminado con éxito.
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error eliminando el flujo:", error);
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={hideAlert}
          timeout={2000}
          showConfirm={false}
        >
          No se pudo eliminar el flujo. Intenta nuevamente.
        </SweetAlert>
      );
    }
  };

  const confirmDeleteFlow = (columnIndex, flowId) => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Eliminar"
        confirmBtnBsStyle="danger"
        cancelBtnText="Cancelar"
        cancelBtnBsStyle="dark"
        title="¿Estás seguro?"
        onConfirm={() => deleteFlow(columnIndex, flowId)}
        onCancel={hideAlert}
        focusCancelBtn
      >
        Esta acción no se puede deshacer.
      </SweetAlert>
    );
  };

  const openStepPanel = (task) => {
    setSelectedTask(task);
    setStepOpen(true)
  };

  const closeStepPanel = () => {
    setSelectedTask(null);
    setStepOpen(false)
  };
  return (
    <React.Fragment>
      {alert}
        <MetaTags>
          <title>TrueContact | Tareas </title>
        </MetaTags>
        <Container fluid>
          <DndProvider backend={HTML5Backend}>
          <div className={`page-content-task ${selectedTask ? "panel-open" : ""}`}
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}>
              
                {Array.isArray(taskFlows)
                  ? taskFlows.map((flow, index) => (
                      <TaskColumn
                        key={flow.id}
                        title={flow.title}
                        tasks={flow.tasks}
                        flowId={flow.id}
                        moveTask={moveTask}
                        addTask={(taskTitle, dueDate, assignee) => {
                          const task = {
                            title: taskTitle,
                            dueDate: dueDate,
                            assignee: assignee,
                            flowId: flow.id,
                          };
                          addTask(task);
                        }}
                        deleteTask={(taskId) => confirmDeleteTask(taskId) }
                        columnIndex={index}
                        deleteColumn={() => confirmDeleteFlow(index, flow.id)}
                        openStepPanel={openStepPanel}
                      />
                    ))
                  : []}
                {/* Botón para agregar un nuevo flujo */}
                <div className="task-column new-flow-column" onClick={() => addColumn("Nuevo Flujo")}>
                  <h5>
                    <i className="fas fa-plus-circle"></i> Agregar Flujo
                  </h5>
                </div>
            </div>
          </DndProvider>
        </Container>
      {stepOpen && (
        <div className="step-panel open">
          <StepPanel task={selectedTask} isOpen={stepOpen} toggle={closeStepPanel} />
        </div>
      )}
    </React.Fragment>
  );
};

export default TaskFlow;
