import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./constants";
import "./TaskFlow.css";

const TaskCard = ({
  task,
  index,
  columnIndex,
  deleteTask,
  openStepPanel,
  moveTask,
}) => {
  const users = [
    { name: "Usuario 1", avatarUrl: "https://via.placeholder.com/32" },
    { name: "Usuario 2", avatarUrl: "https://via.placeholder.com/32" },
    { name: "Usuario 3", avatarUrl: "https://via.placeholder.com/32" },
    { name: "Usuario 4", avatarUrl: "https://via.placeholder.com/32" },
    { name: "Usuario 5", avatarUrl: "https://via.placeholder.com/32" },
    { name: "Usuario 6", avatarUrl: "https://via.placeholder.com/32" },
  ];

  
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { task, index, columnIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    hover: (draggedItem) => {
      if (
        draggedItem.index !== index ||
        draggedItem.columnIndex !== columnIndex
      ) {
        moveTask(
          draggedItem.index,
          draggedItem.columnIndex,
          columnIndex,
          index
        );
        draggedItem.index = index;
        draggedItem.columnIndex = columnIndex;
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="task-card"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => openStepPanel(task)}
    >
      {/* Título de la tarea */}
      <p>{task.title}</p>

      {/* Detalles adicionales: Fecha, etiquetas, etc. */}
      <div className="details">
        <span>
          <i className="fas fa-calendar-alt"></i> {/* Ícono de calendario */}
          {task.dueDate || "Sin fecha"} {/* Fecha o mensaje predeterminado */}
        </span>
        <span>
          <i className="fas fa-tag"></i> {/* Ícono de etiqueta */}
          {task.label || "Sin etiqueta"}{" "}
          {/* Etiqueta o mensaje predeterminado */}
        </span>
      </div>
      <div className="user-section">
  {/* Avatares */}
  <div className="user-avatars">
    {/* Aquí se renderizan solo los primeros 5 usuarios */}
    {users.slice(0, 5).map((user, index) => (
      <div className="user-avatar" key={index}>
        <img src={user.avatarUrl} alt={user.name} />
      </div>
    ))}
    {/* Mostrar "+X" si hay más de 5 usuarios */}
    {users.length > 5 && (
      <div className="extra-users">+{users.length - 5}</div>
    )}
  </div>

  {/* Botón para agregar usuarios */}
  <button className="add-user-btn">
    <i className="fas fa-user-plus"></i>
  </button>
</div>


      {/* Botón eliminar */}
      <span
        className="delete-btn"
        onClick={(e) => {
          e.stopPropagation();
          deleteTask(task.id);
        }}
      >
        ×
      </span>
    </div>
  );
};

export default TaskCard;
