import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import {
  getAgent,
  getCampaignFullList,
  getLeadsforTime,
} from "../../helpers/backend";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
} from "recharts";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js"; // Importa el idioma español

const LeadsForHour = () => {
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState("");
  const [agente, setAgente] = useState("");
  const [campaña, setCampaña] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showWarning, setShowWarning] = useState(false);
  const [selectedGestiones, setSelectedGestiones] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [isLead, setIsLead] = useState(false);
  const [type, setType] = useState("");
  const [showGraphic, setShowGraphic] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const handleSelectTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions || []);
    setType(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const [agent, setAgent] = useState({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data);
  };
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };

  const resetSearch = () => {
    setFechaFin("");
    setFechaInicio("");
    setSelectedDate("");
    setEstado("");
    setAgente("");
    setSelectedAgents("");
    setSelectedCampaign("");
    setSelectedEstado("");
    setSelectedType("");
    setType("");
    setShowGraphic(false);
    setShowWarning(false);
    setIsLead(false);
    getReportData();
  };
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setFechaInicio(today);
    setFechaFin(today);

    // Llamar a `getReportData` solo si ambas fechas están configuradas
    if (fechaInicio && fechaFin) {
      getReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fechaInicio, fechaFin]);

  useEffect(() => {
    getAgentData();
    getCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const transformData = (data) => {
    const transformedData = Object.entries(data)
      .filter(([key]) => key !== "total_leads")
      .map(([timeSlot, { contador, leads }]) => ({
        timeSlot,
        Gestiones: contador,
        leads,
      }));
  
    // Ordenar para asegurar que "antes de las 8" esté al inicio
    const orderedData = [
      ...transformedData.filter(item => item.timeSlot === "antes_8am"),
      ...transformedData.filter(item => item.timeSlot !== "antes_8am")
    ];
  
    const totalGestiones = orderedData.reduce((sum, { Gestiones }) => sum + Gestiones, 0);
  
    return { transformedData: orderedData, totalGestiones };
  };
  

  const { transformedData, totalGestiones } = transformData(data);

  const handleBarClick = (data) => {
    setSelectedGestiones(data.payload.leads || []); // Actualiza la tabla con los leads seleccionados
  };

  const CustomLegend = ({ total }) => (
    <div style={{ textAlign: "center", fontSize: "14px", fontWeight: "bold" }}>
      <p>Total Gestiones: {total}</p>
    </div>
  );

  const getReportData = async () => {
    setSelectedGestiones("");
    setLoading(true);
    const response = await getLeadsforTime(
      `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña}&type=${type}&is_leads=${isLead}`
    );
    console.log(response);
    if (!fechaInicio || !fechaFin) {
      setShowWarning(true);
    }
    setShowGraphic(true);
    setLoading(false);
    setData(response.data);
  };
  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Reporte por Hora </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Reporte por Hora" />
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Fecha"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                              locale: "es",
                            }}
                            onChange={handleDateChange}
                            value={selectedDate}
                          />
                        </Col>
                        <Col>
                          <Select
                            onChange={handleSelectEstadoChange}
                            options={[
                              { value: "", label: "---------" },
                              { value: "EN_GESTION", label: "En Gestión" },
                              { value: "VENCIDO", label: "Vencido" },
                              { value: "FINALIZADO", label: "Finalizado" },
                              { value: "PENDIENTE", label: "Pendiente" },
                            ]}
                            placeholder="Estado"
                            value={selectedEstado}
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={
                              Array.isArray(agent)
                                ? [
                                    { label: "Todos", value: "" },
                                    ...agent.map((item) => ({
                                      label: item.get_full_name,
                                      value: item.id,
                                    })),
                                  ]
                                : []
                            }
                            value={selectedAgents}
                            onChange={handleSelectChange}
                            isMulti={true}
                            placeholder="Agentes"
                            menuPosition="fixed"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={
                              Array.isArray(campaignData)
                                ? [
                                    { label: "----------", value: "" },
                                    ...campaignData.map((item) => ({
                                      label: item.name,
                                      value: item.id,
                                    })),
                                  ]
                                : []
                            }
                            onChange={handleSelectCampaignChange}
                            value={selectedCampaign}
                            menuPosition="fixed"
                            placeholder="Campañas"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={[
                              { value: "WhatsApp", label: "WhatsApp" },
                              {
                                value: "Telefonia - Manual",
                                label: "Telefonía - Manual",
                              },
                            ]}
                            onChange={handleSelectTypeChange}
                            value={selectedType}
                            menuPosition="fixed"
                            placeholder="Tipo"
                          />
                        </Col>
                        <Col>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="isLeadCheck"
                              checked={isLead}
                              onChange={(e) => setIsLead(e.target.checked)}
                            />
                            <Label
                              className="form-check-label"
                              for="isLeadCheck"
                            >
                              Lead?
                            </Label>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <Button
                            color="warning"
                            className="btn-sm me-1 mt-1"
                            onClick={getReportData}
                          >
                            <b>Consultar</b>
                          </Button>
                          <Button
                            color="danger"
                            className="btn-sm me-1 mt-1"
                            onClick={resetSearch}
                          >
                            <b>Borrar</b>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    {showWarning && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        Por favor, coloque un rango de fechas. La fecha es
                        requerida.
                      </div>
                    )}
                  </Row>
                </CardBody>
              </Card>
              {showGraphic ? (
                <Row>
                  <Col lg={6}>
                    <Card>
                      <div className="d-flex justify-content-center mt-3">
                        <BarChart
                          width={800}
                          height={400}
                          data={transformedData}
                        >
                          <XAxis dataKey="timeSlot" />
                          <YAxis domain={[0, "dataMax + 1"]} />
                          <Tooltip />
                          <Legend
                            content={<CustomLegend total={totalGestiones} />}
                          />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Bar
                            dataKey="Gestiones"
                            fill="#8884d8"
                            onClick={handleBarClick}
                          />
                        </BarChart>
                      </div>
                    </Card>
                  </Col>
                  {selectedGestiones.length > 0 && (
                    <Col lg={6}>
                      <div
                        className="custom-scroll"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <Table
                          className="table text-center mb-3 table-sm font-size-15"
                          hover
                        >
                          <thead className="table-dark align-middle">
                            <tr>
                              <th>Contacto</th>
                              <th>Estado</th>
                              <th>Fecha</th>
                              <th>Canal de Comunicación</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedGestiones.map((gestion) => (
                              <tr key={gestion.id}>
                                <td>{gestion.contacto}</td>
                                <td>{gestion.estado}</td>
                                <td>{gestion.created_date}</td>
                                <td>{gestion.type}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  )}
                </Row>
              ) : null}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LeadsForHour;
