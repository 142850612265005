import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// Redux
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// Actions
import { productoList, productoDelete } from "../../../store/actions";

// Import common components
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Pagination from "../../../components/Common/Pagination";
import Loader from "../../../components/Common/Loader";

// Actions
import { productoFilter } from "../../../store/actions";

const ProductoList = (props) => {
  const URL = process.env.REACT_APP_API_URL;
  const url = URL.split('/api')
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(productoList());
  }, [dispatch]);

  const [search, setSearch] = useState("");

  //Delete
  const [confirmText, setConfirmText] = useState(false);
  const [confirmDel, setConfirmDel] = useState("");
  const [cancel, setCancel] = useState(false);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(productoFilter(`?search=${search}`));
  };

  const resetSearch = () => {
    dispatch(productoFilter(`?search=`));
    setSearch("");
  };

  const handleConfirm = (id) => {
    setConfirmText(true);
    setConfirmDel(id);
  };

  const params = {
    count: props.producto.count,
    next: props.producto.next,
    previous: props.producto.previous,
    results: props.producto.results,
  };

  const redirect = () => {
    history.push("/productos");
  };
  console.log(params);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Productos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={"TrueContact"} breadcrumbItem={"Productos"} />
          {props.loading ? (
            <Loader />
          ) : (
            <div>
              <Card className="col-lg-10 mx-auto  mt-3">
                {confirmText ? (
                  <SweetAlert
                    title="¿Estás seguro de que quieres eliminar este Usuario ?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      dispatch(productoDelete(confirmDel));
                    }}
                    onCancel={() => {
                      setConfirmText(false);
                      setCancel(true);
                    }}
                  ></SweetAlert>
                ) : null}

                {cancel ? (
                  <SweetAlert
                    title="Cancelado"
                    warning
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      setCancel(false);
                    }}
                  >
                    La información de usuario esta a salvo
                  </SweetAlert>
                ) : null}

                {props.productoDeleted === true ? (
                  <SweetAlert
                    title="Hecho!"
                    success
                    // timeout={2000}
                    showConfirm={true}
                    onConfirm={() => {
                      setConfirmText(false);
                      dispatch(productoList());
                    }}
                  >
                    Se ha eliminado el Usuario con éxito!
                  </SweetAlert>
                ) : null}

                {props.error.detail ? (
                  <SweetAlert
                    title="Lo Sentimos,"
                    error
                    // timeout={2000}
                    showConfirm={true}
                    onConfirm={() => {
                      redirect();
                    }}
                  >
                    {props.error.detail}
                  </SweetAlert>
                ) : null}

                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="/productos/add_producto"
                      >
                        Agregar{" "}
                        <span className="bx bx-producto-plus fa-lg align-middle"></span>
                      </Link>
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Usuario, nombres, apellidos..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="col-lg-10 mx-auto  mt-3">
                {params.count !== 0 ? (
                  <CardBody className="pb-0">
                    <h4 className="card-title">Lista de usuarios</h4>
                    <div className="table-responsive">
                      <Table className="table text-center mb-3" hover>
                        <thead className="table-dark align-middle">
                          <tr>
                            <th>Imagen</th>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Código</th>
                            <th>Categoría</th>
                            <th>Marca</th>
                            <th>Precio</th>
                            <th>Descripción</th>
                            <th>Diseño</th>
                            <th>País</th>
                            <th>Observaciones</th>
                            <th>Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results &&
                            params.results.map((producto, idx) => (
                              <tr key={idx}>
                                <td>
                                  <img
                                    src={url[0] + 
                                      producto.imagenes?.[0]?.imagen ||
                                      "/path/to/default-image.jpg"
                                    }
                                    alt="Producto"
                                    className="img-thumbnail"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </td>
                                <th scope="row">{idx + 1}</th>
                                <td>{producto.name}</td>
                                <td>{producto.codigo_producto}</td>
                                <td>{producto.categoria}</td>
                                <td>{producto.marca}</td>
                                <td>{producto.precio}</td>
                                <td>{producto.descripcion}</td>
                                <td>
                                  {producto.detalles_producto?.[0]?.diseño ||
                                    "N/A"}
                                </td>
                                <td>
                                  {producto.detalles_producto?.[0]?.pais ||
                                    "N/A"}
                                </td>
                                <td>
                                  {producto.detalles_producto?.[0]
                                    ?.observaciones || "N/A"}
                                </td>
                                <td>
                                  <Link
                                    to={`/productos/${producto.id}/edit_producto`}
                                  >
                                    <span className="fa fa-edit fa-lg align-middle"></span>
                                  </Link>{" "}
                                  <Link
                                    to="#"
                                    onClick={() => handleConfirm(producto.id)}
                                  >
                                    <span className="fa fa-trash fa-lg align-middle"></span>
                                  </Link>{" "}

                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>

                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl="productos/"
                      filterFunction={productoFilter}
                    />
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, producto, productoDeleted } = state.Producto;
  return { error, loading, producto, productoDeleted };
};

export default withRouter(connect(mapStateToProps)(ProductoList));
