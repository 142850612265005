// PRODUCTO requests
export const PRODUCTO_LIST = "PRODUCTO_LIST";
export const PRODUCTO_CREATE = "PRODUCTO_CREATE";
export const PRODUCTO_FILTER = "PRODUCTO_FILTER";
export const PRODUCTO_UPDATE = "PRODUCTO_UPDATE";
export const PRODUCTO_DELETE = "PRODUCTO_DELETE";
export const PRODUCTO_READ = "PRODUCTO_READ";
export const PRODUCTO_PERMISSION = "PRODUCTO_PERMISSION";
export const PRODUCTO_CHANGE_PASSWORD = "PRODUCTO_CHANGE_PASSWORD";

// PRODUCTO success
export const PRODUCTO_LIST_SUCCESS = "PRODUCTO_LIST_SUCCESS";
export const PRODUCTO_CREATE_SUCCESS = "PRODUCTO_CREATE_SUCCESS";
export const PRODUCTO_UPDATE_SUCCESS = "PRODUCTO_UPDATE_SUCCESS";
export const PRODUCTO_DELETE_SUCCESS = "PRODUCTO_DELETE_SUCCESS";
export const PRODUCTO_READ_SUCCESS = "PRODUCTO_READ_SUCCESS";
export const PRODUCTO_CHANGE_PASSWORD_SUCCESS = "PRODUCTO_CHANGE_PASSWORD_SUCCESS";
export const PRODUCTO_PERMISSION_SUCCESS = "PRODUCTO_PERMISSION_SUCCESS";

// PRODUCTO failed
export const PRODUCTO_REQUEST_FAILED = "PRODUCTO_REQUEST_FAILED";
