import { call, put, takeEvery } from "redux-saga/effects";

import {
  PROJECT_LIST,
  PROJECT_FILTER,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DELETE,
} from "./actionTypes";

import {
 projectListSuccess,
 projectCreateSuccess,
 projectUpdateSuccess,
 projectDeleteSuccess,
 projectRequestFailed,
} from "./actions";

import {
  getProjectList,
  getProjectFilter,
  postProjectCreate,
  patchProjectUpdate,
  deleteProject,

} from "../../helpers/backend";

function*projectListSaga() {
  console.log("Trayendo lista de project...");
  try {
    const response = yield call(getProjectList);
    if (response.status === 200) {
      yield put(projectListSuccess(response.data));
    } else {
      yield put(projectRequestFailed(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailed(error.response.data));
  }
}

function*projectCreateSaga({ payload:project }) {
  console.log("Creando nuevo project...");
  try {
    const response = yield call(postProjectCreate,project);
    if (response.status === 201) {
      yield put(projectCreateSuccess(response.data));
    } else {
      yield put(projectRequestFailed(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailed(error.response.data));
  }
}

function*projectFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getProjectFilter, { changeUrl });
    if (response.status === 200) {
      yield put(projectListSuccess(response.data));
    } else {
      yield put(projectRequestFailed(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailed(error.response.data));
  }
}

function*projectUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del PROJECT...");
  try {
    const response = yield call(patchProjectUpdate, { id }, data);
    if (response.status === 200) {
      yield put(projectUpdateSuccess(response.data));
    } else {
      yield put(projectRequestFailed(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailed(error.response.data));
  }
}

function*projectDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del PROJECT...");
  try {
    const response = yield call(deleteProject, id );
    if (response.status === 204) {
      yield put(projectDeleteSuccess(response.data));
    } else {
      yield put(projectRequestFailed(response.data));
    }
  } catch (error) {
    yield put(projectRequestFailed(error.response.data));
  }
}

export default function*projectSaga() {
  yield takeEvery(PROJECT_LIST,projectListSaga);
  yield takeEvery(PROJECT_FILTER,projectFilterSaga);
  yield takeEvery(PROJECT_CREATE,projectCreateSaga);
  yield takeEvery(PROJECT_UPDATE,projectUpdateSaga);
  yield takeEvery(PROJECT_DELETE,projectDeleteSaga);
}
