import { Row, Input, Button, Modal } from "reactstrap";

import { useState } from "react";

import SweetAlert from "react-bootstrap-sweetalert";
import { postCasosMaterials } from "../../helpers/backend";

const ModalMateriales = (props) => {
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [actionAlert, setActionAlert] = useState(false);
  console.log(actionAlert);

  const actionSuccess = async () => {
    try {
      setActionAlert(true); // Mostrar que la acción está en progreso

      const response = await postCasosMaterials({
        caso: props.casosId,
        descripcion: descripcion,
        cantidad: cantidad,
        observaciones: observaciones,
      });

      if (response.status === 201) {
        setShowAlertSuccess(true); // Mostrar éxito
      } else {
        setErrorAlert(true); // Mostrar error
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setErrorAlert(true); // Mostrar error en caso de excepción
    } finally {
      setActionAlert(false); // Ocultar el indicador de acción
    }
  };


  return (
    <>
      {showAlertSuccess ? (
        <SweetAlert
          title="Hecho!"
          success
          showConfirm={false}
          timeout={2000}
          onConfirm={() => {
            props.getCasosData();
            setShowAlertSuccess(false);
            props.handleCloseModal();
          }}
        ></SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Error!"
          danger
          showConfirm={false}
          timeout={2000}
          onConfirm={() => {
            props.getCasosData();
            setErrorAlert(false);
          }}
        ></SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modalMateriales}
        toggle={() => props.handleCloseModal()}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2">Agregar Material</h3>
          <button
            type="button"
            onClick={() => {
              props.handleCloseModal();
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-3 p-2">
            <Input
              type="text"
              onChange={(e) => setDescripcion(e.target.value)}
              placeholder="Descripcion"
            />
          </Row>

          <Row className="mb-3 p-2">
            <Input
              type="text"
              onChange={(e) => setCantidad(e.target.value)}
              placeholder="Cantidad"
            />
          </Row>
          <Row className="mb-3 p-2">
            <Input
              type="text"
              onChange={(e) => setObservaciones(e.target.value)}
              placeholder="Observaciones"
            />
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={() => props.handleCloseModal()}>
            Close
          </Button>
          <Button color="primary" onClick={() => actionSuccess()}>
            Save changes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ModalMateriales;
