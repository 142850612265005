import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { MetaTags } from "react-meta-tags";
import { postProductoCreate } from "../../../helpers/backend";

const ProductAdd = () => {
  const [formData, setFormData] = useState({
    name: "",
    codigo_producto: "",
    categoria: null,
    marca: "",
    precio: "",
    descripcion: "",
    detalles_producto: [
      {
        diseño: "",
        pais: "",
        descripcion: "",
        observaciones: "",
      },
    ],
    imagenes: [{ imagen: "" }],
  });
  const [alert, setAlert] = useState(false);

  const categories = [
    { value: 1, label: "Categoría 1" },
    { value: 1, label: "Categoría 2" },
    { value: 1, label: "Categoría 3" },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onChangeNested = (e, index, field) => {
    const newDetails = [...formData.detalles_producto];
    newDetails[index][field] = e.target.value;
    setFormData({ ...formData, detalles_producto: newDetails });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const response = await postProductoCreate(formData)
    console.log(response)    
    setAlert(true);
  };
  const handleDocumentoChange = (archivos) => {
    Array.from(archivos).forEach((archivo) => {
      var reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = function () {
        var imgDocumento = [];
        var base64 = reader.result;
        imgDocumento = base64.split(",");
        setFormData({ ...formData, imagen: imgDocumento[1]});
      };
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Productos </title>
        </MetaTags>
        <Container fluid>
          <Card className="col-lg-8 mx-auto mt-3">
            {alert && (
              <SweetAlert
                title="Producto Agregado"
                success
                onConfirm={() => setAlert(false)}
              >
                El producto se agregó exitosamente.
              </SweetAlert>
            )}
            <CardBody>
              <h4 className="card-title">Agregar Producto</h4>
              <Form className="needs-validation" onSubmit={onSubmit}>
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label for="name">Nombre del Producto</Label>
                    <Input
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={onChange}
                      required
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Label for="codigo_producto">Código del Producto</Label>
                    <Input
                      id="codigo_producto"
                      name="codigo_producto"
                      value={formData.codigo_producto}
                      onChange={onChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label for="categoria">Categoría</Label>
                    <Select
                      id="categoria"
                      options={categories}
                      onChange={(selectedOption) =>
                        setFormData({
                          ...formData,
                          categoria: selectedOption.value,
                        })
                      }
                      placeholder="Seleccione categoría"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Label for="marca">Marca</Label>
                    <Input
                      id="marca"
                      name="marca"
                      value={formData.marca}
                      onChange={onChange}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="mb-3">
                    <Label for="precio">Precio</Label>
                    <Input
                      id="precio"
                      name="precio"
                      type="number"
                      value={formData.precio}
                      onChange={onChange}
                      required
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Label for="descripcion">Descripción</Label>
                    <Input
                      id="descripcion"
                      name="descripcion"
                      value={formData.descripcion}
                      onChange={onChange}
                      required
                    />
                  </Col>
                </Row>
                <h5 className="mt-4">Detalles del Producto</h5>
                {formData.detalles_producto.map((detalle, index) => (
                  <Row key={index}>
                    <Col lg={3} className="mb-3">
                      <Label for={`diseño-${index}`}>Diseño</Label>
                      <Input
                        id={`diseño-${index}`}
                        value={detalle.diseño}
                        onChange={(e) => onChangeNested(e, index, "diseño")}
                      />
                    </Col>
                    <Col lg={3} className="mb-3">
                      <Label for={`pais-${index}`}>País</Label>
                      <Input
                        id={`pais-${index}`}
                        value={detalle.pais}
                        onChange={(e) => onChangeNested(e, index, "pais")}
                      />
                    </Col>
                    <Col lg={3} className="mb-3">
                      <Label for={`descripcion-${index}`}>Descripción</Label>
                      <Input
                        id={`descripcion-${index}`}
                        value={detalle.descripcion}
                        onChange={(e) =>
                          onChangeNested(e, index, "descripcion")
                        }
                      />
                    </Col>
                    <Col lg={3} className="mb-3">
                      <Label for={`observaciones-${index}`}>
                        Observaciones
                      </Label>
                      <Input
                        id={`observaciones-${index}`}
                        value={detalle.observaciones}
                        onChange={(e) =>
                          onChangeNested(e, index, "observaciones")
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col lg={6}>
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => handleDocumentoChange(e.target.files)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-center">
                    <Button type="submit" color="primary">
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductAdd.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
};

export default ProductAdd;
